<template>
    <div
        v-if="!$store.getters['common/isLoading']"
        class="d-flex flex-column"
    >
        <div class="d-flex flex-column flex-sm-row mb-1">
            <div class="d-flex flex-column mb-8 mb-sm-0">
                <h5 class="h5-text mb-5">Запрос на лизинг # {{request.id}} от {{new Date(request.created_at).toLocaleString()}}</h5>
            </div>
        </div>
        <div class="d-flex flex-column">
            <div class="page-block flex-column">
                <div class="subhead-text mb-6">Инициатор</div>
                <v-row>
                    <v-col cols="12" sm="12" md="2">
                        <div>
                            <p class="label">Дилерский центр</p>
                            <p class="p-content">{{request.leasing_request.user?.manager_ds?.dealership.name}}</p>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                        <div>
                            <p class="label">Менеджер</p>
                            <p class="p-content">{{request.leasing_request?.user?.first_name}} {{request.leasing_request?.user?.last_name}}</p>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                        <div>
                            <p class="label">Телефон</p>
                            <p class="p-content">{{request.leasing_request.user?.phone}}</p>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                        <div>
                            <p class="label">Email</p>
                            <p class="p-content">{{request.leasing_request.user?.email}}</p>
                        </div>
                    </v-col>
                </v-row>
                 <div class="subhead-text mb-6">Покупатель</div>
                <v-row>
                    <v-col cols="12" sm="12" md="2">
                        <div>
                            <p class="label">Наименование компании</p>
                            <p class="p-content">{{request.leasing_request?.company_name}}</p>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                        <div>
                            <p class="label">ИНН</p>
                            <p class="p-content">{{request.leasing_request?.company_inn}}</p>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                        <div>
                            <p class="label">Адрес компании</p>
                            <p class="p-content">{{request.leasing_request?.company_address}}</p>
                        </div>
                    </v-col>
                </v-row>
                <div class="subhead-text mb-8">Информация о транспортном средстве</div>
                <v-row>
                    <v-col cols="12" sm="12" md="2">
                        <div>
                            <p class="label">Марка</p>
                            <p class="p-content">{{carBrands.find(brand => brand.id == request.leasing_request.car_brand_id).name}}</p>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                        <div>
                            <p class="label">Модель</p>
                            <p class="p-content">{{request.leasing_request.car_model.name}}</p>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                        <div>
                            <p class="label">Тип предмета лизинга</p>
                            <p class="p-content">{{subjectTypes.find(subject => subject.id == request.leasing_request.subject_type_id).name }}</p>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="2">
                        <div>
                            <p class="label">Адрес</p>
                            <p class="p-content">{{request.leasing_request.company_address}}</p>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                        <div>
                            <p class="label">Бюджет на авто до</p>
                            <p class="p-content">{{request.leasing_request.price}}</p>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                        <div>
                            <p class="label">Аванс</p>
                            <p class="p-content">{{request.leasing_request.prepaid}}</p>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                        <div>
                            <p class="label">Срок лизинга</p>
                            <p class="p-content">{{request.leasing_request.period}}</p>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <div>
                            <p class="label">Комментарий</p>
                            <p class="p-content">{{request.leasing_request.comment}}</p>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <div class="page-block flex-column mt-8 mb-12" v-if="request.status === 'new'">
                <div class="subhead-text mb-6">Приложите КП на лизинг</div>
                <v-row>
                    <v-col cols="12" sm="12" md="3">
                        <p class="label">Приложите КП на лизинг</p>
                        <v-file-input
                            outlined
                            placeholder="Добавить файл КП"
                            prepend-inner-icon="mdi-file-outline"
                            prepend-icon=""
                            v-model="file"
                        ></v-file-input>
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                        <p class="label">Итоговая сумма договора</p>
                        <v-text-field
                            outlined
                            placeholder="Итоговая сумма договора"
                            v-model="request.final_price"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                        <p class="label">Укажите вероятность одобрения</p>
                        <div>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <div v-on="on" @click="() => {setApprovalStatus(1)}" :class="[`circle`,`red`,{ active: request.approval_probability == 1 }]"></div>
                                </template>
                                <span>Низкая вероятность</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <div v-on="on" @click="() => {setApprovalStatus(2)}" :class="[`circle`,`yellow`,{ active: request.approval_probability == 2 }]"></div>
                                </template>
                                <span>Средняя вероятность</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <div v-on="on" @click="() => {setApprovalStatus(3)}" :class="[`circle`,`green`,{ active: request.approval_probability == 3 }]"></div>
                                </template>
                                <span>Высокая вероятность</span>
                            </v-tooltip>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <v-text-field
                            outlined
                            placeholder="Комментарий (Если необходимо)"
                            v-model="request.comment"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <div class="mt-3">
                    <v-btn
                        width="250"
                        large
                        color="primary"
                        @click="updateRequest"
                    >Отправить КП</v-btn>
                </div>
            </div>
            <div class="page-block flex-column mt-8 mb-8" v-else>
                <div class="subhead-text mb-6">Данные по отправленному КП</div>
                <v-row>
                    <v-col cols="12" sm="12" md="3">
                        <p class="label">КП на лизинг</p>
                        <table class="offer-file-table">
                            <tr v-for="file in request.files" :key="file.id">
                                <td>
                                    <a class="file-link" :href="renderFileLink(file.file_name)" target="blank">{{`${file.file_name.slice(0,16)}...`}}</a>
                                    <span class="remove-file" @click="() => { removeFile(file) }">x</span>
                                </td>
                            </tr>
                        </table>
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                        <p class="label">Итоговая сумма договора</p>
                        <p>{{request.final_price}}</p>
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                        <p class="label">Оставленный комментарий</p>
                        <p>{{request.comment}}</p>
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                        <p class="label">Выбранная вероятность одобрения</p>
                        <div>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <div v-on="on" :class="[`circle`,`red`,{ active: request.approval_probability == 1 }]"></div>
                                </template>
                                <span>Низкая вероятность</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <div v-on="on" :class="[`circle`,`yellow`,{ active: request.approval_probability == 2 }]"></div>
                                </template>
                                <span>Средняя вероятность</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <div v-on="on" :class="[`circle`,`green`,{ active: request.approval_probability == 3 }]"></div>
                                </template>
                                <span>Высокая вероятность</span>
                            </v-tooltip>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <div class="page-block flex-column mb-12" v-if="request.status !== 'new'">
                <div class="subhead-text mb-6">Доотправить КП</div>
                <v-row>
                    <v-col cols="12" sm="12" md="6">
                        <v-file-input
                            outlined
                            placeholder="Добавить файл КП"
                            prepend-inner-icon="mdi-file-outline"
                            prepend-icon=""
                            v-model="file"
                        ></v-file-input>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <v-btn
                            width="250"
                            large
                            color="primary"
                            @click="updateRequest"
                        >Отправить КП</v-btn>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>
<script>
import Offer from '@/apis/Offers'
import Brands from '@/apis/Brands'

export default {
    data() {
        return {
            request: null,
            subjectTypes: [
                {
                name: 'Легковой',
                id: 1
                },
                {
                name: 'Грузовой',
                id: 2
                },
                {
                name: 'Б/У',
                id: 3
                },
                {
                name: 'Коммерческие',
                id: 4
                },
                {
                name: 'Спецтехника',
                id: 5
                },
                {
                name: 'Оборудование',
                id: 6
                }
            ],
            carBrands: [],
            carModels: [],
            file: null,
            requiredFields: ['final_price', 'comment', 'approval_probability']
        }
    },
    mounted() {
        if (this.$route.params.id) {
            Offer.getItem(this.$route.params.id).then(res => {
                console.log(res.data)
                this.request = res.data
            })
             Brands.fetchBrands().then(({ data }) => {
                this.carBrands = data.data
            })
        } else {
            this.showSystemNotification('Данная заявка не найдена')
        }
    },
    methods: {
        renderFileLink(filename) {
            return `http://188.225.11.172:8000/api/storage/commercial_proposals/${filename}`
        },
        formValidation() {
            let isValid = true
            this.requiredFields.forEach(field => {
                if (!this.request[field]) isValid = false
            })
            return isValid
        },
        setApprovalStatus(newValue) {
            this.request.approval_probability = newValue
        },
        updateRequest() {
            if (!this.formValidation()) {
                this.showSystemNotification('Обязательные поля не заполнены')
                return
            }

            if (this.file) {
                const formData = new FormData
                formData.append('offer_file', this.file)
                formData.append('offer_id', this.request.id)
                formData.append('comment', this.request.comment)
                formData.append('final_price', this.request.final_price)
                formData.append('approval_probability', this.request.approval_probability)

                Offer.uploadFile(formData).then(fileRes => {
                    this.showSystemNotification('КП направлено в дилерский центр')
                    this.$router.push('/lc/requests')
                })
            } else {
                this.showSystemNotification('Для подтверждения заявки необходимо прикрепить КП')
            }
        },
        removeFile(file) {
            Offer.removeFile(file.id).then(() => {
                this.showSystemNotification('файл КП удалён из предложения')
                this.request.files = this.request.files.filter(f => f.id !== file.id)
            })
        }
    }
}
</script>

<style scoped>
  .label {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 12px;
    color: #494B58;
  }
  .p-content {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #878686;
  }
  .lc-list-wrapper {
    padding-left: 12px;
    padding-right: 12px;
  }
  .circle {
    display: inline-block;
    width: 34px;
    height: 34px;
    background: #D9D9D9;
    border-radius: 50%;
    margin-right: 8px;
    margin-top: 10px;
    cursor: pointer;
    box-shadow: -2px 2px 12px #a7a7a7;
  }
  .circle.active {
    border: 2px solid #3A76F0;
    border-color: #3A76F0 !important;
  }
  label.custom-checkbox input[type=radio],
  label.custom-checkbox input[type=checkbox] {
    display: none;
  }
  label.custom-checkbox input[type=radio] + span::before,
  label.custom-checkbox input[type=checkbox] + span::before {
    content: ' ';
    display: inline-block;
    width: 32px;
    height: 32px;
    background: #F3F3F3;
    border: 1px solid #C9C9C9;
    border-radius: 4px;
    margin-right: 6px;
    font-size: 22px;
    text-align: center;
  }
  label.custom-checkbox input[type=radio]:checked + span::before,
  label.custom-checkbox input[type=checkbox]:checked + span::before {
    content: '✔';
    background: #EBF9FF;
    border: 1px solid #3A76F0;
    color: #3A76F0;
  }
.offer-file-table tr {
    font-size: 14px;
  }

  .offer-file-table td {
    padding: 2px 8px;
  }

  .offer-file-table tr:nth-child(2n){
    background: #f1f1f1;
  }

  .offer-file-table td a {
    text-decoration: none;
  }

  .offer-file-table .remove-file {
    margin-left: 4px;
    cursor: pointer;
  }
</style>
